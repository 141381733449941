@import '../../variables.css';


.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 1);
    padding: 20px;
    color: var(--primaryColor);
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 0 4px rgba(0, 0, 0, .14), 0 4px 8px rgba(0, 0, 0, .06);
}

.logo {
    width: 222px;
    height: 42px;
    background-image: url('../../assets/images/logo.jpg');
    background-repeat: no-repeat;
    background-size: contain;
}

.flags {
    height: 100%;
}

.flag {
    box-shadow: 0 0 4px rgba(0, 0, 0, .14), 0 4px 8px rgba(0, 0, 0, .06);
    margin-left: 30px;
    cursor: pointer;
    height: 30px;
    width: 40px;
}

@media(max-width: 600px) {
    .logo {
        width: 170px;
        height: 30px;
        background-image: url('../../assets/images/logo.jpg');
        background-repeat: no-repeat;
        background-size: contain;
    }

    .flag {
        margin-left: 10px;

    }

}