@import '../../variables.css';

.title {
    margin-top: 100px;
    color: var(--primaryColor)
}

.application-page {
    margin-top: 70px;
}

.page-img {
    width: 250px;
    height: 250px;
    margin-bottom: 40px;
}

.configuration {
    width: 95%;
    margin: 40px auto;
    border-radius: 10px;
    padding: 10px;
    background-color: #dddddd;
}

.install_img{
margin: 0 auto;
height: 700px;
background-size: contain;
background-position: center;
background-repeat: no-repeat;
margin-bottom: 20px;
max-width: 300px;
}

.install_img:nth-of-type(1){
    height: 220px;
    }

    .install_img:nth-of-type(2){
    
        height: 270px;
        }
        .install_img:nth-of-type(4){
    
            height: 200px;
            }
    

