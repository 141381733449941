@import '../../variables.css';

.loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    z-index: 100;




}

.loader__container {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;


}

svg {
    fill: var(--primaryColor);
}