@import '../../variables.css';

.modal-bcg {
    position: fixed;
    background-color: rgba(13, 21, 45, 0.9);
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}

.modal-container {
    width: 90%;
    max-width: 400px;
    background-color: white;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    padding: 20px;
    color: var(--primaryColor);
}

.close-icon {
    position: absolute;
    right: 20px;
    cursor: pointer;
    width: 20px;
    height: 20px;
}