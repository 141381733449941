@import '../../../variables.css';

.dashboard {
    font-family: var(--primaryFont);
    display: flex;
    flex-wrap: wrap;
    color: var(--primaryColor);
    justify-content: center;
    margin-top: 70px;
}

.title {
    width: 100%;
    margin-bottom: 60px;
}

.list-element {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--primaryColor);
    width: 300px;
    height: 300px;
    margin: 30px;
    cursor: pointer;
    box-shadow: 0 0 4px rgba(0, 0, 0, .14), 0 4px 8px rgba(0, 0, 0, .06);
    border-radius: 10px;
    overflow: hidden;

}

.element-img {
    margin: 10px auto;
    height: 240px;
    width: 240px;
}

.element-legend {
    background-color: var(--primaryColor);
    color: white;
    padding: 10px 5px;

}